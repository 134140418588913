<template>
  <div>
    <b-card-actions
        ref="refreshCard"
        action-refresh
        disable-loading-on-refresh
        @refresh="getItems"
        :loaded.sync="loaded"
    >
      <table-header :filter="filter" :per-page.sync="perPage" />

      <b-overlay :show="!loaded" rounded="sm">
        <b-table ref="refTransactionsListTable" class="position-relative" responsive primary-key="id"
                 :items="items" :per-page="perPage" :current-page="currentPage" :fields="tableColumns"
                 :sort-by.sync="sortBy" :filter="filter.input" @filtered="onFiltered" show-empty
                 :empty-text="$t('No matching records found')"
        >
          <template #head()="data">
            {{ $t(data.label) }}
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button v-on:click="deleteItem(data.item.id)" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      type="submit" size="sm"
            >
              {{ $t('Delete') }}
            </b-button>
          </template>
        </b-table>
      </b-overlay>

      <table-footer
          :current-page.sync="currentPage"
          :per-page="perPage"
          :length="totalRows"
      />

    </b-card-actions>
  </div>
</template>
<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions'
import TableHeader from '@/views/components/TableHeader'
import TableFooter from '@/views/components/TableFooter'
import moment from "moment";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    BCardActions,
    TableHeader,
    TableFooter,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaded: false,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: {
        input: null
      },
      tableColumns: [
        {
          key: 'status',
          sortable: true,
          label: 'Status',
          formatter: (value) => {
            if (value == 0) return this.$t('In progress')
            if (value == 1) return this.$t('Executed')
            if (value == 2) return this.$t('Declined')
          }
        },
        {
          key: 'created_at',
          sortable: true,
          label: 'Created at',
          formatter: (value) => {
            return moment(String(value)).format('DD.MM.YYYY HH:mm:ss')
          }
        },
        {
          key: 'amount',
          sortable: true,
          label: 'Amount in EUR',
        },
        {
          key: 'paymentmethod.last_four',
          sortable: true,
          label: 'last 4 digits of the IBAN',
        },
      ],
      items: [],
    }
  },

  mounted() {
    this.getItems()
  },
  methods: {
    getItems() {
      this.items = []
      this.loaded = false

      this.helperGetItems(`/affiliates/transactions`)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
